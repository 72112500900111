#footer {
  text-align: center;
  font-size: smaller;
}

.controls {
  margin-top: 2em;

  ul {
  
    li {
      display: inline-block;
      margin-right: 2em;
    }
  }
}
